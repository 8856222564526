import axios from "axios";

export default {
    search(params) {
        return axios.post(window.API_BASE + '/user-invites/search', params);
    },

    create(params) {
        return axios.post(window.API_BASE + '/user-invites', params);
    },

    delete(id) {
        return axios.delete(window.API_BASE + '/user-invites/' + id);
    }
}