<template>
  <div class="invites-table">
    <!-- Toolbar -->
    <div class="toolbar">
      <div class="left">
        <div class="toolbar-option">
          <v-select label="Trusted" v-model="trustedFilter" :items="trustedDropdownOptions" class="form-control">
          </v-select>
        </div>
      </div>
      <div class="right">
        <v-text-field v-model="searchQuery" label="Search" type="text"></v-text-field>
      </div>
    </div>
    <!-- / Toolbar -->

    <v-data-table :headers="headers" :items="userInvites" :options.sync="options"
      :server-items-length="totalUserInvites" :loading="loading" class="elevation-1">
      <template v-slot:item.trusted="{ item }">
        <v-icon class="success--text" v-if="item.trusted">mdi-check</v-icon>
        <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small depressed color="danger" dark @click.native.prevent="attemptToRevoke(item.id)">
          <v-icon small class="me-2">mdi-delete</v-icon>
          Revoke
        </v-btn>
      </template>
    </v-data-table>

    <!-- Attempting to revoke-->
    <v-dialog v-model="showRevokeModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Are you sure?</v-card-title>
        <v-card-text>
          Are you sure you want to revoke the invite for
          <b>{{ attemptingToRevoke ? attemptingToRevoke.name : "Unknown" }}</b>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isRevoking" color="primary darken-1" text @click="attemptingToRevokeId = null">
            Cancel
          </v-btn>
          <v-btn :disabled="isRevoking" :loading="isRevoking" color="red darken-1" text @click="attemptRevoke">
            Yes, Revoke
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Attempting to revoke-->
  </div>
</template>

<script>
import userInvites from "../../api/user-invites";
const _ = require('lodash');
import eventBus from "../../eventBus";
export default {
  data() {
    return {
      trustedDropdownOptions: [
        {
          value: null,
          text: "Show All",
        },
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],

      searchQuery: "",
      trustedFilter: null,

      totalUserInvites: 0,
      userInvites: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        // { text: "Email", value: "email" },
        // { text: "Phone Number", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Trusted", value: "trusted" },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],

      attemptingToRevokeId: null,
      isRevoking: false
    };
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },

    trustedFilter() {
      this.fetch();
    },

    searchQuery() {
      this.fetch();
    }
  },
  methods: {
    getFormParams() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      params.resultsPerPage = itemsPerPage;
      params.page = page;
      if (sortBy.length) {
        params.sortField = _.first(sortBy);
      }
      if (sortDesc.length) {
        let isDescending = _.first(sortDesc);
        params.sortOrder = isDescending ? "descend" : "ascend";
      }

      if (this.trustedFilter !== null) {
        params.trusted = this.trustedFilter;
      }

      if (this.searchQuery && this.searchQuery.trim().length) {
        params.q = this.searchQuery;
      }

      return params;
    },

    fetch() {
      this.loading = true;
      userInvites.search(this.getFormParams()).then((r) => {
        this.userInvites = r.data.data;
        this.totalUserInvites = r.data.total;
        this.loading = false;
      });
    },

    attemptToRevoke(id) {
      this.attemptingToRevokeId = id;
    },

    attemptRevoke() {
      if (!this.attemptingToRevokeId) {
        return false;
      }
      let vm = this;
      vm.isRevoking = true;
      userInvites.delete(this.attemptingToRevokeId)
      .then((r) => {
        vm.isRevoking = false;
        vm.$toast.success("Invite revoked successfully");
        vm.attemptingToRevokeId = null;
        vm.fetch();
      }).catch(e => {
        console.log(e);
        vm.isRevoking = false;
        vm.$toast.error('Error revoking invite. Please try again.');
      });
    }
  },
  computed: {
    showRevokeModal: {
      get() {
        return this.attemptingToRevokeId !== null;
      },
      set(val) {
        if (!val) {
          this.attemptingToRevokeId = null;
        }
      }
    },

    attemptingToRevoke() {
      if (this.attemptingToRevokeId) {
        return _.find(this.userInvites, { id: this.attemptingToRevokeId });
      }
      return null;
    }
  },
  created() {
    let vm = this;
    eventBus.$on('user-invite-sent', function() {
      vm.fetch();
    });
  }
};
</script>

<style lang="scss">
.invites-table {
  .toolbar {
    margin-bottom: 10px;
    display: flex;

    flex-direction: column;

    @media screen and (min-width: 960px) {
      flex-direction: row;

      .left {
        display: flex;
      }

      .toolbar-option {
        max-width: 300px;
        margin-right: 30px;
      }

      .right {
        .v-input {
          width: 300px;
        }
      }
    } 

    .left {
      flex-grow: 1;
      // display: flex;
    }

    .right {
      flex-shrink: 1;
    }

    .toolbar-option {
      width: 100%;
      // max-width: 300px;
      // margin-right: 30px;
    }
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>