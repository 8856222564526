<template>
  <div class="invites">
    <v-container fluid>
      <div class="welcome">
        <h1>Consignor Invites</h1>
        <v-btn color="primary" depressed @click.native.prevent="showInviteModal = true">
          <v-icon class="me-2">mdi-plus</v-icon>
          Invite Consignor
        </v-btn>
      </div>

      <v-dialog v-model="showInviteModal" persistent max-width="600px">
        <v-card>
          <v-card-title class="text-h5 mb-4">Invite Consignor</v-card-title>

          <v-card-text>
            <v-form ref="form" @submit.prevent="attemptSendInvite">
              <v-row>
                <v-col :cols="12" :md="6">
                  <v-text-field :disabled="isInviting" label="Name" v-model="inviteInvestorForm.name" :rules="nameRules"
                    required />
                </v-col>
                <v-col :cols="12" :md="6">
                  <v-text-field :disabled="isInviting" label="Email" v-model="inviteInvestorForm.email"
                    :rules="emailRules" type="email" required />
                </v-col>
              </v-row>

              <div style="margin-top: 10px; font-size: 16px;">
                Trusted?
              </div>
              <v-radio-group row v-model="inviteInvestorForm.trusted">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="isInviting" color="red darken-1" text @click="showInviteModal = false">
              Cancel
            </v-btn>
            <v-btn :disabled="isInviting" :loading="isInviting" color="primary darken-1" text
              @click="attemptSendInvite">
              Send Invite
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <user-invites-table></user-invites-table>
    </v-container>
  </div>
</template>

<script>
import UserInvitesTable from "../../../components/user-invites/UserInvitesTable.vue";
import userInvites from "../../../api/user-invites";
import Forms from "../../../mixins/Forms";
import eventBus from "../../../eventBus";
const _ = require("lodash");
export default {
  components: { UserInvitesTable },
  mixins: [Forms],
  data() {
    return {
      showInviteModal: false,
      isInviting: false,
      inviteInvestorForm: {
        name: "",
        email: "",
        trusted: true
      },
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [(v) => !!v || "Email is required"],
    };
  },
  methods: {
    attemptSendInvite() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.sendInvite();
      }
    },

    sendInvite() {
      let vm = this;
      vm.isInviting = true;
      userInvites
        .create(this.inviteInvestorForm)
        .then((r) => {
          vm.isInviting = false;
          vm.$forceUpdate();
          vm.$toast.success("Invite sent successfully");
          vm.showInviteModal = false;
          vm.inviteInvestorForm = {
            name: "",
            email: "",
            trusted: true
          };
          eventBus.$emit('user-invite-sent');
        })
        .catch((e) => {
          console.log(e);
          vm.isInviting = false;
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.invites {
  padding: 15px;

  .welcome {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    h1 {
      flex-grow: 1;
    }
  }
}
</style>